/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AbwesenheitsModel,
    AbwesenheitsModelFromJSON,
    AbwesenheitsModelFromJSONTyped,
    AbwesenheitsModelToJSON,
    MobileAbwesenheitsUebersichtModel,
    MobileAbwesenheitsUebersichtModelFromJSON,
    MobileAbwesenheitsUebersichtModelFromJSONTyped,
    MobileAbwesenheitsUebersichtModelToJSON,
    Tagesstempel,
    TagesstempelFromJSON,
    TagesstempelFromJSONTyped,
    TagesstempelToJSON,
} from './';

/**
 * Liste aller Mitarbeiter mit zugeordneten Abwesenheiten
 * @export
 * @interface AbwesenheitsUebersichtModel
 */
export interface AbwesenheitsUebersichtModel {
    /**
     * Tage des Monats
     * @type {Array<string>}
     * @memberof AbwesenheitsUebersichtModel
     */
    tage?: Array<string> | null;
    /**
     * Alle Abwesenheiten aller Mitarbeiter
     * @type {Array<AbwesenheitsModel>}
     * @memberof AbwesenheitsUebersichtModel
     */
    abwesenheiten?: Array<AbwesenheitsModel> | null;
    /**
     * Gesetzte Tagesstempel
     * @type {Array<Tagesstempel>}
     * @memberof AbwesenheitsUebersichtModel
     */
    tagesstempel?: Array<Tagesstempel> | null;
    /**
     * Filterung im Web anzeigen
     * @type {boolean}
     * @memberof AbwesenheitsUebersichtModel
     */
    hasAbteilung?: boolean;
    /**
     * Filterung im Web anzeigen
     * @type {boolean}
     * @memberof AbwesenheitsUebersichtModel
     */
    hasStandort?: boolean;
    /**
     * Filterung im Web deaktivieren
     * @type {boolean}
     * @memberof AbwesenheitsUebersichtModel
     */
    showAll?: boolean;
    /**
     * Liste aller Mitarbeiter mit zugeordneten Abwesenheiten für Mobile
     * @type {Array<MobileAbwesenheitsUebersichtModel>}
     * @memberof AbwesenheitsUebersichtModel
     */
    mobileAnsicht?: Array<MobileAbwesenheitsUebersichtModel> | null;
}

export function AbwesenheitsUebersichtModelFromJSON(json: any): AbwesenheitsUebersichtModel {
    return AbwesenheitsUebersichtModelFromJSONTyped(json, false);
}

export function AbwesenheitsUebersichtModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbwesenheitsUebersichtModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tage': !exists(json, 'tage') ? undefined : json['tage'],
        'abwesenheiten': !exists(json, 'abwesenheiten') ? undefined : (json['abwesenheiten'] === null ? null : (json['abwesenheiten'] as Array<any>).map(AbwesenheitsModelFromJSON)),
        'tagesstempel': !exists(json, 'tagesstempel') ? undefined : (json['tagesstempel'] === null ? null : (json['tagesstempel'] as Array<any>).map(TagesstempelFromJSON)),
        'hasAbteilung': !exists(json, 'hasAbteilung') ? undefined : json['hasAbteilung'],
        'hasStandort': !exists(json, 'hasStandort') ? undefined : json['hasStandort'],
        'showAll': !exists(json, 'showAll') ? undefined : json['showAll'],
        'mobileAnsicht': !exists(json, 'mobileAnsicht') ? undefined : (json['mobileAnsicht'] === null ? null : (json['mobileAnsicht'] as Array<any>).map(MobileAbwesenheitsUebersichtModelFromJSON)),
    };
}

export function AbwesenheitsUebersichtModelToJSON(value?: AbwesenheitsUebersichtModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tage': value.tage,
        'abwesenheiten': value.abwesenheiten === undefined ? undefined : (value.abwesenheiten === null ? null : (value.abwesenheiten as Array<any>).map(AbwesenheitsModelToJSON)),
        'tagesstempel': value.tagesstempel === undefined ? undefined : (value.tagesstempel === null ? null : (value.tagesstempel as Array<any>).map(TagesstempelToJSON)),
        'hasAbteilung': value.hasAbteilung,
        'hasStandort': value.hasStandort,
        'showAll': value.showAll,
        'mobileAnsicht': value.mobileAnsicht === undefined ? undefined : (value.mobileAnsicht === null ? null : (value.mobileAnsicht as Array<any>).map(MobileAbwesenheitsUebersichtModelToJSON)),
    };
}


